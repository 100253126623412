import { configureStore } from '@reduxjs/toolkit'
import { baseApi } from '../services/api'
import { api as graphqlApi } from '../services/graphql-api'
import { combineReducers } from 'redux'
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createReduxEnhancer } from '@sentry/react'
import themeReducer from '@features/theme/theme.slice'
import authReducer from '@features/auth/auth-slice'
import toastReducer from '@features/toast/toast.slice'
import discussionReducer from '@features/discussion/discussion.slice'
import inventoryReducer from '@features/inventory/inventory.slice'
import whiteboardReducer from '@features/whiteboard/whiteboard.slice'
import panelsReducer from '@features/panels/panels.slice'
import recordingsReducer from '@features/recordings/recordings.slice'
import eventReducer from '@features/event/event-slice'
import chatReducer from '@features/chat/chat.slice'
import surveysReducer from '@features/surveys/surveys.slice'

import { DEV } from '@config'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['theme', 'languages'],
}

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      whitelist: ['user', 'tokens', 'firstTime', 'firstTimeHuddle'],
    },
    authReducer,
  ),
  toast: toastReducer,
  whiteboard: whiteboardReducer,
  // Survey display state.
  surveys: persistReducer(
    {
      key: 'surveys',
      storage,
      whitelist: ['hasCompletedExitSurvey'], // Only persist completion status
    },
    surveysReducer,
  ),
  panels: panelsReducer,
  discussion: discussionReducer,
  event: eventReducer,
  theme: themeReducer,
  inventory: persistReducer(
    {
      key: 'inventory',
      storage,
    },
    inventoryReducer,
  ),
  recordings: recordingsReducer,
  chat: chatReducer,
})

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
})

// Configure the store with persistReducer, sentryReduxEnhancer, and middleware
export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: DEV,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'whiteboard/setRoom'],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ["meta.arg","whiteboard.room"],
        // Ignore these paths in the state
        ignoredPaths: ['whiteboard.room'],
      },
    })
      .concat(baseApi.middleware)
      .concat(graphqlApi.middleware)
      .concat(({ dispatch, getState }) => (next) => (action) => {
        if (action.type === 'auth/logout') {
          // When LOGOUT action is detected, dispatch the api util action to reset API state
          dispatch(baseApi.util.resetApiState())
        }
        return next(action)
      }),
  enhancers: [sentryReduxEnhancer],
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
