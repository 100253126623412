import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    pictionaryGetPictionaryGamesConfig: build.query<
      PictionaryGetPictionaryGamesConfigApiResponse,
      PictionaryGetPictionaryGamesConfigApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/pictionary/config` }),
    }),
    pictionaryJoinGame: build.mutation<PictionaryJoinGameApiResponse, PictionaryJoinGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}/start`,
        method: 'POST',
        params: { exit_: queryArg.exit },
      }),
    }),
    pictionaryGetGame: build.query<PictionaryGetGameApiResponse, PictionaryGetGameApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}` }),
    }),
    pictionaryMakeGuess: build.mutation<PictionaryMakeGuessApiResponse, PictionaryMakeGuessApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}/guess`,
        method: 'POST',
        body: queryArg.pictionaryGuess,
      }),
    }),
    pictionaryAddGameRoundGuess: build.mutation<
      PictionaryAddGameRoundGuessApiResponse,
      PictionaryAddGameRoundGuessApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}/add_current_round_guess`,
        method: 'POST',
        body: queryArg.pictionaryGuess,
      }),
    }),
    pictionaryExitGame: build.mutation<PictionaryExitGameApiResponse, PictionaryExitGameApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}/exit`,
        method: 'POST',
      }),
    }),
    pictionaryNextRound: build.mutation<PictionaryNextRoundApiResponse, PictionaryNextRoundApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/pictionary/game/${queryArg.eventId}/next`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type PictionaryGetPictionaryGamesConfigApiResponse =
  /** status 200 Successful Response */ PictionaryGameConfig
export type PictionaryGetPictionaryGamesConfigApiArg = void
export type PictionaryJoinGameApiResponse = /** status 200 Successful Response */ any
export type PictionaryJoinGameApiArg = {
  eventId: string
  exit?: boolean
}
export type PictionaryGetGameApiResponse = /** status 200 Successful Response */ PictionaryGame
export type PictionaryGetGameApiArg = {
  eventId: string
}
export type PictionaryMakeGuessApiResponse = /** status 200 Successful Response */ any
export type PictionaryMakeGuessApiArg = {
  eventId: string
  pictionaryGuess: PictionaryGuess
}
export type PictionaryAddGameRoundGuessApiResponse =
  /** status 200 Successful Response */ AddCurrentRoundGuessResponse
export type PictionaryAddGameRoundGuessApiArg = {
  eventId: string
  pictionaryGuess: PictionaryGuess
}
export type PictionaryExitGameApiResponse = /** status 200 Successful Response */ any
export type PictionaryExitGameApiArg = {
  eventId: string
}
export type PictionaryNextRoundApiResponse = /** status 200 Successful Response */ any
export type PictionaryNextRoundApiArg = {
  eventId: string
}
export type PictionaryGameConfig = {
  time_to_guess: number
  number_of_words: number
  number_of_rounds: number
  number_of_guesses: number
  min_number_of_users: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type GameStatus = 'PENDING' | 'STARTED' | 'FINISHED' | 'CANCELED'
export type Pronouns = 0 | 1 | 2 | 3
export type TeacherProfileResponse = {
  bio?: string | null
  experience?: string | null
  tags?: string[] | null
  video_link?: string | null
  teaching_style?: string | null
  price_per_hour?: number | null
  partner?: boolean | null
  is_reviewer?: boolean | null
}
export type LanguageExperience = 0 | 1 | 2 | 3 | 4
export type LanguageProficiency = {
  language: string
  proficiency: LanguageExperience
}
export type AddressInfoInDb = {
  country?: string | null
  city?: string | null
  state?: string | null
  address?: string | null
  zip_code?: string | null
}
export type UserStatus = 'STUDENT' | 'TEACHER'
export type Permission =
  | 'CREATE_EVENT'
  | 'CREATE_PENGGUIN_EVENT'
  | 'SEND_DIRECT_MESSAGE'
  | 'CREATE_CUSTOM_ROLE'
  | 'SEND_EMAIL_TO_USERS'
  | 'CREATE_GEOGUESSER_GAME'
  | 'JOIN_GEOGUESSER_GAME'
  | 'MAKE_GUESS_GEOGUESSER_GAME'
  | 'MAKE_RATING'
  | 'UPLOAD_FILE'
  | 'DELETE_ACCOUNT'
  | 'MAKE_ROLE'
  | 'ASSIGN_ROLE'
  | 'VIEW_ROLES'
  | 'EDIT_ROLE'
  | 'GENERATE_AGORA_TOKEN'
  | 'REFRESH_AGORA_TOKEN'
  | 'CREATE_AMBASSADOR'
  | 'DELETE_AMBASSADOR'
  | 'UPDATE_AMBASSADOR'
  | 'TRANSLATE'
  | 'CREATE_DISCORD_ROLE'
  | 'VIEW_DISCORD_ROLE'
  | 'CREATE_GEOGUESSER_PLACES'
  | 'HYPERBEAM_LIST_SESSIONS'
  | 'HYPERBEAM_DELETE_SESSIONS'
  | 'ADMIN_VIEW_USERS'
  | 'ADMIN_LANGUAGES'
  | 'ADMIN_AGORA_TOKENS_AUDIT'
  | 'ADMIN_ROLE'
  | 'ADMIN_DIRECT_MESSAGE'
  | 'ADMIN_AMBASSADORS'
  | 'ADMIN_DISCORD_ROLES'
  | 'ADMIN_DISCORD_AUDIT_EVENT'
  | 'ADMIN_DISCUSSION_LANGUAGE'
  | 'ADMIN_DISCUSSION_LANGUAGE_AUDIT_EVENTS'
  | 'ADMIN_USER_FLAGGING'
  | 'ADMIN_GEOGAME_ROUNDS'
  | 'ADMIN_GEOGAME_STATIC_COORDINATES'
  | 'ADMIN_GEOGAME_AUDIT_EVENTS'
  | 'ADMIN_EVENT_GAME_CHAT'
  | 'ADMIN_EVENT_CHAT'
  | 'ADMIN_EVENT_PARTICIPANTS'
  | 'ADMIN_PICTIONARY_AUDIT'
  | 'ADMIN_PICTIONARY_GAMES'
  | 'ADMIN_PICTIONARY_ROUNDS'
  | 'ADMIN_PICTIONARY_GUESS'
  | 'ADMIN_PICTIONARY_WORDS'
  | 'ADMIN_TRANSLATION_AUDIT'
  | 'ADMIN_REDIS_CONSOLE'
  | 'ADMIN_DEBUGGING_CONSOLE'
  | 'CREATE_TRANSCRIPT'
  | 'ADMIN_RECORDINGS'
  | 'HUDDLE_HOST'
export type RolesInDb = {
  id?: number | null
  name?: string | null
  permissions?: Permission[] | null
}
export type PublicUserProfile = {
  id?: number | null
  username?: string | null
  email?: string | null
  bio?: string | null
  image?: string | null
  full_name?: string | null
  country?: string | null
  pronouns?: Pronouns | null
  date_of_birth?: string | null
  profile?: TeacherProfileResponse | null
  completed_onboarding?: boolean | null
  verified?: boolean | null
  accent?: string | null
  voice_gender?: string | null
  learning?: LanguageProficiency[] | null
  address?: AddressInfoInDb | null
  speaking?: LanguageProficiency[] | null
  status?: UserStatus
  display_name?: string | null
  is_company?: boolean | null
  cefr_level?: string | null
  roles?: RolesInDb[] | null
  plan?: string | null
}
export type PictionaryGame = {
  created_time: string
  status: GameStatus
  end_time?: string | null
  started_time?: string | null
  drawer?: PublicUserProfile | null
  current_round?: number | null
  current_word?: string | null
  round_remaining_time?: number | null
  scores: {
    [key: string]: number
  }
  list_words?: string[] | null
  chosen_word_length?: number | null
}
export type PictionaryGuess = {
  guess: string
  round_number: number
}
export type AddCurrentRoundGuessResponse = {
  current_word: string
}
export const {
  usePictionaryGetPictionaryGamesConfigQuery,
  useLazyPictionaryGetPictionaryGamesConfigQuery,
  usePictionaryJoinGameMutation,
  usePictionaryGetGameQuery,
  useLazyPictionaryGetGameQuery,
  usePictionaryMakeGuessMutation,
  usePictionaryAddGameRoundGuessMutation,
  usePictionaryExitGameMutation,
  usePictionaryNextRoundMutation,
} = injectedRtkApi
