import {
  useEventDisableVideoUserEventMutation,
  useEventEnableVideoUserEventMutation,
  useEventMuteEventMutation,
  useEventSendWsMessageMutation,
  useEventUnmuteEventMutation,
} from '@app/services'
import { useToggle } from '@common/hooks'
import { useCurrentEvent } from '@features/event/hooks'
import KickStudentModal from '@features/panels/components/kick-student-modal'
import { LocalVideoTrack, RemoteVideoTrack } from 'livekit-client'
import React from 'react'
import { Box, Button, Stack, Popover, IconButton, Icon } from '@common/components'
import {
  RecordVoiceOverOutlinedIcon,
  MicOffOutlinedIcon,
  MicNoneOutlinedIcon,
  VideocamOutlinedIcon,
  VideocamOffOutlinedIcon,
  Person2OutlinedIcon,
  CancelOutlinedIcon,
  LoopIcon,
  PersonAddAltIcon,
  FlagIcon,
  MenuIcon,
  AspectRatioIcon,
} from '@common/icons'
import ReportUser from '@features/auth/components/report-user/report-user'
import FollowUser from '@features/auth/components/follow-user'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { motion } from 'motion/react'
import {
  FocusToggle,
  TrackReferenceOrPlaceholder,
  useRemoteParticipants,
} from '@livekit/components-react'
import { useUser } from '@features/auth/hooks'

const PopoverMenu = (props: {
  trackReference: TrackReferenceOrPlaceholder
  muted?: boolean
  videoTrack?: boolean
  id: string
  children: React.ReactNode
}) => {
  const { trackReference, muted, videoTrack, id, children } = props
  const { isHost } = useCurrentEvent()
  const { user } = useUser()
  const { open, handleClose, handleOpen } = useToggle(false)
  const [sendWs] = useEventSendWsMessageMutation()
  const [muteUser] = useEventMuteEventMutation()
  const [unmuteUser] = useEventUnmuteEventMutation()
  const remoteParticipants = useRemoteParticipants()
  const [disableCamera] = useEventDisableVideoUserEventMutation()
  const [enableCamera] = useEventEnableVideoUserEventMutation()
  const { event } = useCurrentEvent()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const handleReload = async (userId: number) => {
    await sendWs({
      eventId: event?.event_id as string,
      sendWsMessageRequest: { event_type: 'REFRESH', for_user_id: userId, ext: {} },
    })
  }
  return (
    <>
      <KickStudentModal open={open} onClose={handleClose} id={id} />
      {id && Number(id) !== user?.id ? (
        <>
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            {children}
            <IconButton
              onMouseEnter={handlePopoverOpen}
              sx={{
                display: 'flex',
                position: 'absolute',
                top: '7px',
                right: '7px',
                gap: 1,
                zIndex: 110,
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            disablePortal
            onMouseLeave={handlePopoverClose}
            sx={{
              pointerEvents: 'auto',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Stack onMouseLeave={handlePopoverClose} spacing={2} sx={{ p: 2 }}>
              {remoteParticipants.length > 0 && (
                <Stack direction='row' alignItems='center' spacing={2}>
                  <AspectRatioIcon sx={{ color: '#8F9BBA' }} />
                  <FocusToggle
                    trackRef={trackReference}
                    style={{ width: '100%', maxHeight: '24px', padding: 0 }}
                  >
                    <Button fullWidth variant='contained' color='neutral'>
                      <FocusToggle
                        trackRef={trackReference}
                        style={{
                          backgroundColor: 'transparent',
                          maxWidth: '24px',
                          maxHeight: '24px',
                          padding: '8px',
                        }}
                      />
                      Expand
                    </Button>
                  </FocusToggle>
                </Stack>
              )}
              {isHost || muted === true ? (
                <Stack direction='row' alignItems='center' spacing={2}>
                  <RecordVoiceOverOutlinedIcon sx={{ color: '#8F9BBA' }} />
                  {muted === false ? (
                    <Button
                      fullWidth
                      startIcon={<MicOffOutlinedIcon />}
                      variant='outlined'
                      onClick={() => {
                        muteUser({ eventId: event?.event_id as string, userId: Number(id) })
                      }}
                    >
                      Turn off mic
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      startIcon={<MicNoneOutlinedIcon />}
                      variant='contained'
                      onClick={() => {
                        unmuteUser({ eventId: event?.event_id, userId: id })
                      }}
                    >
                      Ask to unmute
                    </Button>
                  )}
                </Stack>
              ) : null}
              {isHost || videoTrack === true ? (
                <Stack direction='row' alignItems='center' spacing={2}>
                  <VideocamOutlinedIcon sx={{ color: '#8F9BBA' }} />
                  {videoTrack && isHost ? (
                    <Button
                      fullWidth
                      startIcon={<VideocamOffOutlinedIcon />}
                      variant='outlined'
                      onClick={() => {
                        disableCamera({ eventId: event?.event_id, userId: id })
                      }}
                    >
                      Turn off camera
                    </Button>
                  ) : (
                    <Button
                      startIcon={<VideocamOutlinedIcon />}
                      variant='contained'
                      onClick={() => {
                        enableCamera({ eventId: event?.event_id, userId: id })
                      }}
                    >
                      Ask to turn on webcam
                    </Button>
                  )}
                </Stack>
              ) : null}
              <Stack direction='row' alignItems='center' spacing={2}>
                <PersonAddAltIcon sx={{ color: '#8F9BBA' }} />
                {event?.users?.length}
                <FollowUser
                  userToFollow={event?.users?.find((u) => u.id === Number(id))}
                  sx={{ width: '100%', borderRadius: '5px' }}
                />
              </Stack>
              <Stack direction='row' alignItems='center' spacing={2}>
                <FlagIcon sx={{ color: '#8F9BBA' }} />
                <ReportUser
                  lightColor='rgba(35,35,25,0.7)'
                  userToReport={event?.users?.find((u) => u.id === Number(id))}
                  sx={{ width: '100%', borderRadius: '5px' }}
                />
              </Stack>
              {isHost && (
                <>
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Person2OutlinedIcon sx={{ color: '#8F9BBA' }} />
                    <Button
                      startIcon={<CancelOutlinedIcon />}
                      variant='outlined'
                      color='error'
                      fullWidth
                      onClick={handleOpen}
                    >
                      Kick
                    </Button>
                  </Stack>
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <LoopIcon sx={{ color: '#8F9BBA' }} />
                    <Button
                      fullWidth
                      startIcon={<LoopIcon />}
                      variant='outlined'
                      color='primary'
                      onClick={() => handleReload(Number(id))}
                    >
                      Reload
                    </Button>
                  </Stack>
                </>
              )}
            </Stack>
          </Popover>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: 'inline-block',
              position: 'relative',
              width: '100%',
              height: '100%',
            }}
          >
            {children}
          </Box>
          {remoteParticipants.length > 0 && (
            <IconButton
              sx={{
                position: 'absolute',
                top: '7px',
                right: '7px',
                zIndex: 110,
                backgroundColor: 'transparent',
              }}
            >
              <FocusToggle
                trackRef={trackReference}
                style={{
                  backgroundColor: 'transparent',
                  maxWidth: '24px',
                  maxHeight: '24px',
                  padding: '8px',
                }}
              />
            </IconButton>
          )}
        </>
      )}
    </>
  )
}

// const MotionButton = motion.create(IconButton)

// const IconMenu = (props: {
//   trackReference: TrackReferenceOrPlaceholder
//   onMenuClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
//   onMouseEnterPopover: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
//   onMouseLeavePopover: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
// }) => {
//   const { trackReference, onMenuClick, onMouseEnterPopover, onMouseLeavePopover } = props
//   const [isOpen, setIsOpen] = React.useState(false)
//   const timeoutRef = React.useRef<NodeJS.Timeout>()

//   const handleMouseLeave = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
//     timeoutRef.current = setTimeout(() => {
//       setIsOpen(false)
//     }, 1000) // 500ms delay before closing
//     onMouseLeavePopover(event)
//   }

//   const handleMouseEnter = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
//     clearTimeout(timeoutRef.current) // Clear any existing timeout
//     setIsOpen(true)
//     onMouseEnterPopover(event)
//   }

//   const itemProps = {
//     tabIndex: isOpen ? 0 : -1,
//     sx: isOpen
//       ? {}
//       : {
//           position: 'absolute',
//           top: '0',
//           left: '0',
//         },
//     animate: {
//       opacity: isOpen ? 1 : 0,
//     },
//     transition: {
//       type: 'spring',
//       bounce: 0.15,
//       duration: 0.2,
//     },
//   }

//   return (
//     <motion.div
//       layout
//       style={{
//         display: 'flex',
//         position: 'absolute',
//         top: '7px',
//         right: '7px',
//         gap: 1,
//         zIndex: 110,
//       }}
//     >
//       <MotionButton layout {...itemProps}>
//         <FocusToggle
//           trackRef={trackReference}
//           style={{
//             backgroundColor: 'transparent',
//             maxWidth: '24px',
//             maxHeight: '24px',
//             padding: '8px',
//           }}
//         />
//       </MotionButton>
//       <MotionButton
//         layout
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         sx={{ zIndex: 30 }}
//       >
//         <MenuIcon />
//       </MotionButton>
//     </motion.div>
//   )
// }

export default PopoverMenu
