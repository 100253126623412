export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL
export const GRAPHQL_BASE_URL = import.meta.env.VITE_GRAPHQL_BASE_URL
export const MODE = import.meta.env.MODE // mode env
export const PROD = import.meta.env.MODE === 'production' // true if prod env
export const DEV = import.meta.env.MODE === 'development' // true if dev env
export const BASE_URL = window.location.origin
export const AGORA_APP_ID = import.meta.env.VITE_AGORA_APP_ID
export const SENTRY = import.meta.env.VITE_SENTRY === 'true'
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
export const CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID
export const RECORDING_BUCKET = import.meta.env.VITE_RECORDING_BUCKET
export const DARKLY_CLIENT_SIDE_ID = import.meta.env.VITE_DARKLY_CLIENT_SIDE_ID

export const LIVEKIT_SERVER_URL = import.meta.env.VITE_LIVEKIT_SERVER_URL

export const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
export const HOTJAR_SNIPPET_VERSION = import.meta.env.VITE_HOTJAR_SNIPPET_VERSION

export const IS_UNSW = import.meta.env.VITE_IS_UNSW === 'true'
export const ENABLE_ANALYTICS = import.meta.env.VITE_ENABLE_ANALYTICS === 'true'
export const PRESENCE_URL = import.meta.env.VITE_PRESENCE_URL

export const POSTHOG_KEY = import.meta.env.VITE_POSTHOG_KEY

export const POSTHOG_HOST = import.meta.env.VITE_POSTHOG_HOST

// manual configs
export const DISABLE_PREFERRED_TEACHER_MODAL = true

export const GTM_ID = import.meta.env.VITE_GTM_ID || 'AW-16770246324'
