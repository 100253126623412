import { baseApi as api } from '../api'
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    revisionGetRevision: build.query<RevisionGetRevisionApiResponse, RevisionGetRevisionApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/revisions`,
        params: { limit: queryArg.limit, offset: queryArg.offset, language: queryArg.language },
      }),
    }),
    revisionDeleteRevision: build.mutation<
      RevisionDeleteRevisionApiResponse,
      RevisionDeleteRevisionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/revision/<revision_id>`,
        method: 'DELETE',
        params: { revision_id: queryArg.revisionId },
      }),
    }),
    revisionCreateTranscription: build.mutation<
      RevisionCreateTranscriptionApiResponse,
      RevisionCreateTranscriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/transcript`,
        method: 'POST',
        body: queryArg.transcriptionRequest,
      }),
    }),
    revisionGetUserTranscription: build.query<
      RevisionGetUserTranscriptionApiResponse,
      RevisionGetUserTranscriptionApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/revision/transcripts` }),
    }),
    revisionUploadChunkedAudio: build.mutation<
      RevisionUploadChunkedAudioApiResponse,
      RevisionUploadChunkedAudioApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/record/upload_chunked_audio/${queryArg.recordId}/${queryArg.chunkNumber}`,
        method: 'POST',
        body: queryArg.bodyUploadChunkedAudioApiV1ExtensionsRevisionRecordUploadChunkedAudioRecordIdChunkNumberPost,
        params: { live_transcribe: queryArg.liveTranscribe, is_exam_mode: queryArg.isExamMode },
      }),
    }),
    revisionStopRecording: build.mutation<
      RevisionStopRecordingApiResponse,
      RevisionStopRecordingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/record/stop_recording/${queryArg.recordId}`,
        method: 'POST',
        params: { test_myself: queryArg.testMyself },
      }),
    }),
    revisionCancelRecording: build.mutation<
      RevisionCancelRecordingApiResponse,
      RevisionCancelRecordingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/record/${queryArg.recordId}`,
        method: 'DELETE',
      }),
    }),
    revisionCreateChunkRecordingSettings: build.mutation<
      RevisionCreateChunkRecordingSettingsApiResponse,
      RevisionCreateChunkRecordingSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recording/${queryArg.recordingId}/settings`,
        method: 'POST',
        body: queryArg.createRecordingChunkSettingsRequest,
      }),
    }),
    revisionListRecordings: build.query<
      RevisionListRecordingsApiResponse,
      RevisionListRecordingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recordings`,
        params: {
          test_myself: queryArg.testMyself,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    revisionUpdateRecording: build.mutation<
      RevisionUpdateRecordingApiResponse,
      RevisionUpdateRecordingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recordings/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.updateRecordingRequest,
      }),
    }),
    revisionGetRecording: build.query<RevisionGetRecordingApiResponse, RevisionGetRecordingApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/revision/recordings/${queryArg.id}` }),
    }),
    revisionDeleteRecording: build.mutation<
      RevisionDeleteRecordingApiResponse,
      RevisionDeleteRecordingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recordings/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    revisionCreateFeedbackRequests: build.mutation<
      RevisionCreateFeedbackRequestsApiResponse,
      RevisionCreateFeedbackRequestsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/feedback_request`,
        method: 'POST',
        body: queryArg.askFeedbackRequest,
      }),
    }),
    revisionListFeedbackRequests: build.query<
      RevisionListFeedbackRequestsApiResponse,
      RevisionListFeedbackRequestsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/revision/feedback_requests` }),
    }),
    revisionCreateTeacherFeedback: build.mutation<
      RevisionCreateTeacherFeedbackApiResponse,
      RevisionCreateTeacherFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/feedback`,
        method: 'POST',
        body: queryArg.createFeedbackRequest,
      }),
    }),
    revisionGetFeedbacks: build.query<RevisionGetFeedbacksApiResponse, RevisionGetFeedbacksApiArg>({
      query: () => ({ url: `/api/v1/extensions/revision/feedbacks` }),
    }),
    revisionDeleteFeedback: build.mutation<
      RevisionDeleteFeedbackApiResponse,
      RevisionDeleteFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/feedback/${queryArg.id}`,
        method: 'DELETE',
      }),
    }),
    revisionCreateAudioTeacherFeedback: build.mutation<
      RevisionCreateAudioTeacherFeedbackApiResponse,
      RevisionCreateAudioTeacherFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/audio_feedback`,
        method: 'POST',
        body: queryArg.bodyCreateAudioTeacherFeedbackApiV1ExtensionsRevisionAudioFeedbackPost,
      }),
    }),
    revisionAddUserPreferenceOnFeedback: build.mutation<
      RevisionAddUserPreferenceOnFeedbackApiResponse,
      RevisionAddUserPreferenceOnFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/feedback/${queryArg.id}/user_taste`,
        method: 'POST',
        body: queryArg.addUserPreferenceRequest,
      }),
    }),
    revisionGetUserPreferenceOnFeedback: build.query<
      RevisionGetUserPreferenceOnFeedbackApiResponse,
      RevisionGetUserPreferenceOnFeedbackApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/feedback/${queryArg.id}/user_taste`,
      }),
    }),
    revisionTranscriptionWebhook: build.mutation<
      RevisionTranscriptionWebhookApiResponse,
      RevisionTranscriptionWebhookApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/transcription/webhook`,
        method: 'POST',
        body: queryArg.transcriptionWebHookRequest,
      }),
    }),
    revisionCreateRecordingChunk: build.mutation<
      RevisionCreateRecordingChunkApiResponse,
      RevisionCreateRecordingChunkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recording/${queryArg.recordingId}/chunk`,
        method: 'POST',
        body: queryArg.createRecordingChunkRequest,
      }),
    }),
    revisionGetDefaultTeacher: build.query<
      RevisionGetDefaultTeacherApiResponse,
      RevisionGetDefaultTeacherApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/revision/default_teacher` }),
    }),
    revisionGetQuestions: build.query<RevisionGetQuestionsApiResponse, RevisionGetQuestionsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/${queryArg.recordingId}/questions`,
        params: { limit: queryArg.limit },
      }),
    }),
    revisionGetSummary: build.query<RevisionGetSummaryApiResponse, RevisionGetSummaryApiArg>({
      query: (queryArg) => ({ url: `/api/v1/extensions/revision/${queryArg.recordingId}/summary` }),
    }),
    revisionGetTtsUrl: build.mutation<RevisionGetTtsUrlApiResponse, RevisionGetTtsUrlApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/convert/tts`,
        method: 'POST',
        body: queryArg.ttsRequest,
      }),
    }),
    revisionGetActiveStudents: build.query<
      RevisionGetActiveStudentsApiResponse,
      RevisionGetActiveStudentsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/stats/active-students`,
        params: {
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          granularity: queryArg.granularity,
        },
      }),
    }),
    revisionGetGeneralStats: build.query<
      RevisionGetGeneralStatsApiResponse,
      RevisionGetGeneralStatsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/revision/stats/general` }),
    }),
    revisionGetAiAnalysis: build.query<
      RevisionGetAiAnalysisApiResponse,
      RevisionGetAiAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/stats/ai-analysis`,
        params: { page: queryArg.page, page_size: queryArg.pageSize },
      }),
    }),
    revisionGetStudentPerformance: build.query<
      RevisionGetStudentPerformanceApiResponse,
      RevisionGetStudentPerformanceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/status/student-performance`,
        params: {
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          granularity: queryArg.granularity,
          feedback_type: queryArg.feedbackType,
        },
      }),
    }),
    revisionGetRecordingLimits: build.query<
      RevisionGetRecordingLimitsApiResponse,
      RevisionGetRecordingLimitsApiArg
    >({
      query: () => ({ url: `/api/v1/extensions/revision/recordings-limits` }),
    }),
    revisionProcessRecording: build.mutation<
      RevisionProcessRecordingApiResponse,
      RevisionProcessRecordingApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/extensions/revision/recordings/${queryArg.id}/process`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as api }
export type RevisionGetRevisionApiResponse = /** status 200 Successful Response */ WordDbModel[]
export type RevisionGetRevisionApiArg = {
  limit?: number | null
  offset?: number | null
  language?: string | null
}
export type RevisionDeleteRevisionApiResponse = /** status 204 Successful Response */ void
export type RevisionDeleteRevisionApiArg = {
  revisionId: number
}
export type RevisionCreateTranscriptionApiResponse =
  /** status 201 Successful Response */ TranscriptionResponse
export type RevisionCreateTranscriptionApiArg = {
  transcriptionRequest: TranscriptionRequest
}
export type RevisionGetUserTranscriptionApiResponse =
  /** status 200 Successful Response */ TranscriptionResponse[]
export type RevisionGetUserTranscriptionApiArg = void
export type RevisionUploadChunkedAudioApiResponse = /** status 200 Successful Response */ any
export type RevisionUploadChunkedAudioApiArg = {
  recordId: string
  chunkNumber: number
  liveTranscribe?: boolean | null
  isExamMode?: boolean | null
  bodyUploadChunkedAudioApiV1ExtensionsRevisionRecordUploadChunkedAudioRecordIdChunkNumberPost: BodyUploadChunkedAudioApiV1ExtensionsRevisionRecordUploadChunkedAudioRecordIdChunkNumberPost
}
export type RevisionStopRecordingApiResponse = /** status 200 Successful Response */ any
export type RevisionStopRecordingApiArg = {
  recordId: string
  testMyself?: boolean | null
}
export type RevisionCancelRecordingApiResponse = /** status 204 Successful Response */ void
export type RevisionCancelRecordingApiArg = {
  recordId: string
}
export type RevisionCreateChunkRecordingSettingsApiResponse =
  /** status 201 Successful Response */ any
export type RevisionCreateChunkRecordingSettingsApiArg = {
  recordingId: string
  createRecordingChunkSettingsRequest: CreateRecordingChunkSettingsRequest
}
export type RevisionListRecordingsApiResponse =
  /** status 200 Successful Response */ RecordingSmallerResponse[]
export type RevisionListRecordingsApiArg = {
  testMyself?: boolean | null
  limit?: number | null
  offset?: number | null
}
export type RevisionUpdateRecordingApiResponse =
  /** status 200 Successful Response */ RecordingResponse
export type RevisionUpdateRecordingApiArg = {
  id: number
  updateRecordingRequest: UpdateRecordingRequest
}
export type RevisionGetRecordingApiResponse =
  /** status 200 Successful Response */ RecordingResponse
export type RevisionGetRecordingApiArg = {
  id: number
}
export type RevisionDeleteRecordingApiResponse = /** status 200 Successful Response */ any
export type RevisionDeleteRecordingApiArg = {
  id: number
}
export type RevisionCreateFeedbackRequestsApiResponse =
  /** status 201 Successful Response */ AskFeedbackResponse
export type RevisionCreateFeedbackRequestsApiArg = {
  askFeedbackRequest: AskFeedbackRequest
}
export type RevisionListFeedbackRequestsApiResponse =
  /** status 200 Successful Response */ ListFeedbackRequestResponse
export type RevisionListFeedbackRequestsApiArg = void
export type RevisionCreateTeacherFeedbackApiResponse =
  /** status 201 Successful Response */ CreateFeedbackResponse
export type RevisionCreateTeacherFeedbackApiArg = {
  createFeedbackRequest: CreateFeedbackRequest
}
export type RevisionGetFeedbacksApiResponse =
  /** status 200 Successful Response */ RecordingChunkCommentResponse[]
export type RevisionGetFeedbacksApiArg = void
export type RevisionDeleteFeedbackApiResponse =
  /** status 200 Successful Response */ DeleteChunkCommentResponse
export type RevisionDeleteFeedbackApiArg = {
  id: number
}
export type RevisionCreateAudioTeacherFeedbackApiResponse =
  /** status 201 Successful Response */ CreateFeedbackResponse
export type RevisionCreateAudioTeacherFeedbackApiArg = {
  bodyCreateAudioTeacherFeedbackApiV1ExtensionsRevisionAudioFeedbackPost: BodyCreateAudioTeacherFeedbackApiV1ExtensionsRevisionAudioFeedbackPost
}
export type RevisionAddUserPreferenceOnFeedbackApiResponse =
  /** status 201 Successful Response */ UserPreferenceResponse | null
export type RevisionAddUserPreferenceOnFeedbackApiArg = {
  id: number
  addUserPreferenceRequest: AddUserPreferenceRequest
}
export type RevisionGetUserPreferenceOnFeedbackApiResponse =
  /** status 200 Successful Response */ UserPreferenceResponse | null
export type RevisionGetUserPreferenceOnFeedbackApiArg = {
  id: number
}
export type RevisionTranscriptionWebhookApiResponse = /** status 200 Successful Response */ any
export type RevisionTranscriptionWebhookApiArg = {
  transcriptionWebHookRequest: TranscriptionWebHookRequest
}
export type RevisionCreateRecordingChunkApiResponse =
  /** status 201 Successful Response */ CreateRecordingChunkResponse
export type RevisionCreateRecordingChunkApiArg = {
  recordingId: number
  createRecordingChunkRequest: CreateRecordingChunkRequest
}
export type RevisionGetDefaultTeacherApiResponse = /** status 200 Successful Response */ any
export type RevisionGetDefaultTeacherApiArg = void
export type RevisionGetQuestionsApiResponse = /** status 200 Successful Response */ QuestionResponse
export type RevisionGetQuestionsApiArg = {
  recordingId: string
  limit?: number
}
export type RevisionGetSummaryApiResponse =
  /** status 200 Successful Response */ RecordingSummaryResponse
export type RevisionGetSummaryApiArg = {
  recordingId: number
}
export type RevisionGetTtsUrlApiResponse = /** status 200 Successful Response */ TtsResponse
export type RevisionGetTtsUrlApiArg = {
  ttsRequest: TtsRequest
}
export type RevisionGetActiveStudentsApiResponse =
  /** status 200 Successful Response */ ActiveStudentsResponse[]
export type RevisionGetActiveStudentsApiArg = {
  dateFrom: string
  dateTo: string
  granularity: string
}
export type RevisionGetGeneralStatsApiResponse =
  /** status 200 Successful Response */ GeneralStatsResponse
export type RevisionGetGeneralStatsApiArg = void
export type RevisionGetAiAnalysisApiResponse =
  /** status 200 Successful Response */ AiAnalysisListResponse
export type RevisionGetAiAnalysisApiArg = {
  page?: number
  pageSize?: number
}
export type RevisionGetStudentPerformanceApiResponse =
  /** status 200 Successful Response */ StudentPerformanceResponse
export type RevisionGetStudentPerformanceApiArg = {
  dateFrom: string
  dateTo: string
  granularity?: string
  feedbackType?: string
}
export type RevisionGetRecordingLimitsApiResponse =
  /** status 200 Successful Response */ RecordingLimitsResponse
export type RevisionGetRecordingLimitsApiArg = void
export type RevisionProcessRecordingApiResponse = /** status 200 Successful Response */ any
export type RevisionProcessRecordingApiArg = {
  id: number
}
export type WordDbModel = {
  id: number
  word: string
  complexity: number
  language_id: number
  created_at?: string
  updated_at?: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type TranscriptionResponse = {
  id: number
  transcript_url: string
  audio_url: string
  correction_url: string
}
export type TranscriptionRequest = {
  transcript: string
  audio: string
  correction: string
  user_id: number
}
export type BodyUploadChunkedAudioApiV1ExtensionsRevisionRecordUploadChunkedAudioRecordIdChunkNumberPost =
  {
    file: Blob
  }
export type CreateRecordingChunkSettingsRequest = {
  chunk_size: number
  channels?: number | null
  sample_rate?: number | null
  sample_width?: number | null
  event_id?: string | null
}
export type RecordingSmallerResponse = {
  id: number
  audio_url: string
  language?: string | null
  duration: number
  processed: boolean
  created_at: string
  updated_at: string
  processing_status?: string | null
  locked?: boolean
  test_myself?: boolean
}
export type PublicUserProfile = {
  id: number
  username: string
  image?: string | null
  full_name?: string | null
  avatar?: string | null
}
export type RecordingChunkCommentResponse = {
  id: number
  comment?: string | null
  audio_comment_url?: string | null
  is_ai: boolean
  user?: PublicUserProfile | null
  likes: object
  dislikes: object
  created_at: string
  updated_at: string
  feedback?: string | null
  corrected_sentence?: string | null
  ext?: object | null
  comment_type?: string | null
  video_url?: string | null
}
export type RecordingChunkResponse = {
  id: number
  start_time: number
  end_time: number
  comments: RecordingChunkCommentResponse[]
  created_at: string
  updated_at: string
  error_type?: string | null
}
export type RecordingResponse = {
  id: number
  audio_url: string
  processed_audio_url?: string | null
  transcript_url?: string | null
  event_id?: number | null
  lesson_exist: boolean
  language?: string | null
  duration: number
  processed: boolean
  chunks: RecordingChunkResponse[]
  created_at: string
  updated_at: string
  ext?: object | null
  processing_status?: string | null
  locked?: boolean
  test_myself?: boolean
}
export type UpdateRecordingRequest = {
  reviewed: boolean
}
export type AskFeedbackResponse = {
  id: number
  user_id: number
  teacher_id: number
  recording_id: number
  status: string
  created_at: string
}
export type AskFeedbackRequest = {
  recording_id: number
  teacher_id: number
}
export type FeedbackRequestResponse = {
  id: number
  status: string
  teacher_id: number
  user_id: number
  recording_id: number
  student_display_name?: string | null
  teacher_display_name?: string | null
  created_at: string
}
export type ListFeedbackRequestResponse = {
  received?: FeedbackRequestResponse[] | null
  sent?: FeedbackRequestResponse[] | null
}
export type CreateFeedbackResponse = {
  id: number
  user_id: number
  comment?: string | null
  audio_comment_url?: string | null
  video_url?: string | null
  is_ai: boolean
  created_at: string
}
export type CreateFeedbackRequest = {
  recording_id: number
  comment?: string | null
  start_time: number
  end_time: number
  video_url?: string | null
}
export type DeleteChunkCommentResponse = {
  id: number
  recording_id: number
}
export type BodyCreateAudioTeacherFeedbackApiV1ExtensionsRevisionAudioFeedbackPost = {
  recording_id: number
  start_time: number
  end_time: number
  file: Blob
}
export type UserPreferenceResponse = {
  id: number
  feedback_id: number
  recording_id: number
  preference?: boolean | null
  created_at: string
}
export type AddUserPreferenceRequest = {
  taste?: boolean | null
}
export type AlignedSentences = {
  sentence: string
  start: number
  end: number
}
export type TranscriptionWebHookRequest = {
  recording_id: number
  aligned_sentences: AlignedSentences[]
  subtitle: string
  passcode: string
  detected_lang: string
  result: object
}
export type CreateRecordingChunkResponse = {
  id: number
  recording_id: number
  start_time: number
  end_time: number
}
export type CreateRecordingChunkRequest = {
  start_time: number
  end_time: number
}
export type Question = {
  question: string
  tts_url?: string | null
}
export type QuestionResponse = {
  questions: Question[]
}
export type RecordingSummaryResponse = {
  ideation: object
  grammar: object
  development: object
  recording_id: number
  rephrases: any[]
  vocabulary: object
  fluency: object
}
export type TtsResponse = {
  tts_url: string
}
export type TtsRequest = {
  text: string
  accent?: string
  gender?: string
}
export type ActiveStudentsResponse = {
  date: string
  active_students: number
}
export type GeneralStatsResponse = {
  number_of_students: number
  number_of_recordings: number
  total_duration: number
  avg_recordings_per_student: number
  avg_recording_length: number
}
export type AiAnalysisResponse = {
  cohort_year: number
  cohort_semester: number
  recording_date: string
  teacher_username: string
  student_username: string
  original_sentence_transcript: string | null
  ai_comment: string
  category_of_error: string
}
export type AiAnalysisListResponse = {
  data: AiAnalysisResponse[]
  total: number
  page: number
  page_size: number
}
export type PerformanceDataPoint = {
  date: string
  score: number
}
export type StudentPerformanceResponse = {
  ideation: PerformanceDataPoint[] | null
  development: PerformanceDataPoint[] | null
  grammar: PerformanceDataPoint[] | null
  vocabulary: PerformanceDataPoint[] | null
  fluency: PerformanceDataPoint[] | null
}
export type RecordingLimitsResponse = {
  monthly_limit: number
  current_count: number
  remaining_count: number
}
export const {
  useRevisionGetRevisionQuery,
  useLazyRevisionGetRevisionQuery,
  useRevisionDeleteRevisionMutation,
  useRevisionCreateTranscriptionMutation,
  useRevisionGetUserTranscriptionQuery,
  useLazyRevisionGetUserTranscriptionQuery,
  useRevisionUploadChunkedAudioMutation,
  useRevisionStopRecordingMutation,
  useRevisionCancelRecordingMutation,
  useRevisionCreateChunkRecordingSettingsMutation,
  useRevisionListRecordingsQuery,
  useLazyRevisionListRecordingsQuery,
  useRevisionUpdateRecordingMutation,
  useRevisionGetRecordingQuery,
  useLazyRevisionGetRecordingQuery,
  useRevisionDeleteRecordingMutation,
  useRevisionCreateFeedbackRequestsMutation,
  useRevisionListFeedbackRequestsQuery,
  useLazyRevisionListFeedbackRequestsQuery,
  useRevisionCreateTeacherFeedbackMutation,
  useRevisionGetFeedbacksQuery,
  useLazyRevisionGetFeedbacksQuery,
  useRevisionDeleteFeedbackMutation,
  useRevisionCreateAudioTeacherFeedbackMutation,
  useRevisionAddUserPreferenceOnFeedbackMutation,
  useRevisionGetUserPreferenceOnFeedbackQuery,
  useLazyRevisionGetUserPreferenceOnFeedbackQuery,
  useRevisionTranscriptionWebhookMutation,
  useRevisionCreateRecordingChunkMutation,
  useRevisionGetDefaultTeacherQuery,
  useLazyRevisionGetDefaultTeacherQuery,
  useRevisionGetQuestionsQuery,
  useLazyRevisionGetQuestionsQuery,
  useRevisionGetSummaryQuery,
  useLazyRevisionGetSummaryQuery,
  useRevisionGetTtsUrlMutation,
  useRevisionGetActiveStudentsQuery,
  useLazyRevisionGetActiveStudentsQuery,
  useRevisionGetGeneralStatsQuery,
  useLazyRevisionGetGeneralStatsQuery,
  useRevisionGetAiAnalysisQuery,
  useLazyRevisionGetAiAnalysisQuery,
  useRevisionGetStudentPerformanceQuery,
  useLazyRevisionGetStudentPerformanceQuery,
  useRevisionGetRecordingLimitsQuery,
  useLazyRevisionGetRecordingLimitsQuery,
  useRevisionProcessRecordingMutation,
} = injectedRtkApi
