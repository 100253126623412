import { useEventGetHuddleHostsQuery } from '@app/services'

export const useIsHost = (userId?: number, eventHostId?: number) => {
  const { data: huddleHosts = [] } = useEventGetHuddleHostsQuery()

  if (!userId) {
    return false
  }

  const isEventHost = userId === eventHostId

  const isHuddleHost = huddleHosts.includes(userId)
  return isEventHost || isHuddleHost
}
