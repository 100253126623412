// Survey slice for Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import posthog from 'posthog-js'

// State for exit survey display and completion
interface SurveysState {
  shouldShowExitSurvey: boolean
  hasCompletedExitSurvey: boolean
}

// Initial state for exit survey display and completion
const initialState: SurveysState = {
  shouldShowExitSurvey: false,
  hasCompletedExitSurvey: false,
}

// Actions for exit survey display and completion
const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    triggerExitSurvey: (state, action: PayloadAction<number>) => {
      // Retrieve ID and make a request to determine if user has filled survey or not, if yes, set shouldShowExitSurvey to false

      state.shouldShowExitSurvey = true

      console.log('triggerExitSurvey')
      console.log('action.payload', action.payload)
    },
    completeExitSurvey: (
      state,
      action: PayloadAction<{ surveyId: string; surveyValue: string }>,
    ) => {
      state.shouldShowExitSurvey = false
      state.hasCompletedExitSurvey = true

      // Capture survey completion event
      console.log('action.payload', action.payload)
      posthog.capture('survey sent', {
        $survey_id: action.payload.surveyId, // required
        $survey_response: action.payload.surveyValue, // required
        // $surveyId: action.payload.surveyId,
        // $surveyValue: action.payload.surveyValue,
        // $completedAt: new Date().toISOString()
      })
      // posthog.capture("survey sent", {
      //   $survey_id: surveyID, // required
      //   $survey_response: value // required
      // })
    },

    dismissExitSurvey: (state, action: PayloadAction<{ surveyId: string }>) => {
      state.shouldShowExitSurvey = false

      // Capture survey dismissal event
      posthog.capture('Survey Dismissed', {
        $surveyId: action.payload.surveyId,
        $dismissedAt: new Date().toISOString(),
      })
    },
  },
})
// Export actions for use in other components
export const { triggerExitSurvey, completeExitSurvey, dismissExitSurvey } = surveysSlice.actions
export default surveysSlice.reducer
