interface SurveyModalProps {
  question: string
  onClose: () => void
  onSubmit: (value: string) => void
}
import React, { useRef } from 'react'
export const SurveyModal: React.FC<SurveyModalProps> = ({ question, onClose, onSubmit }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const handleSubmit = () => {
    const value = textareaRef.current?.value || ''
    onSubmit(value)
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '24px',
          borderRadius: '12px',
          maxWidth: '400px',
          width: '90%',
          position: 'relative',
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            background: 'none',
            border: 'none',
            fontSize: '24px',
            cursor: 'pointer',
            padding: '4px',
          }}
        >
          ×
        </button>

        <h2
          style={{
            marginTop: 0,
            color: 'black',
            fontSize: '20px',
            marginBottom: '24px',
            fontWeight: 'bold',
          }}
        >
          {question}
        </h2>

        <textarea
          ref={textareaRef}
          style={{
            width: '100%',
            minHeight: '120px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #ddd',
            marginBottom: '20px',
            resize: 'vertical',
          }}
          placeholder='Start typing...'
        />

        <button
          onClick={handleSubmit}
          style={{
            width: '100%',
            padding: '12px',
            backgroundColor: '#0000FF',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          Submit
        </button>
      </div>
    </div>
  )
}
