import { eventApi, EventResponse, useEventParticipateInEventMutation } from '@app/services'
import { Button } from '@common/components'
import { EVENT_ROUTE, SIMPLE_LOGIN_ROUTE } from '@common/constants/routes'
import { useAppDispatch } from '@common/hooks'
import { CheckCircleOutlineIcon } from '@common/icons'
import { useUser } from '@features/auth/hooks'
import { useNavigate } from '@lib/router'
import dayjs from 'dayjs'
import {
  requestNotificationPermission,
  scheduleEventNotification,
} from '@common/utils/notifications'

const ParticipantButton = (props: {
  isDashboard?: boolean
  event: EventResponse
  width?: string
}) => {
  const { width = '180px', isDashboard = false, event } = props
  const { user } = useUser()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [participateInEvent, { isLoading: isLoadingParticipateInEvent }] =
    useEventParticipateInEventMutation()

  const handleParticipate = async (eventId: string) => {
    const patchResult = dispatch(
      eventApi.util.updateQueryData(
        'eventGetEvents',
        {
          isDashboard,
        },
        (draft) => {
          draft.events
            ?.find((e) => e.event_id === eventId)
            ?.participants.push({ id: user?.id, username: user?.username, image: user?.image })
        },
      ),
    )
    try {
      await participateInEvent({ eventId: eventId }).unwrap()

      // Request notification permission and schedule notifications
      const permission = await requestNotificationPermission()
      if (permission === 'granted') {
        const startTime = dayjs.utc(event.start_time_utc).local()

        // Schedule 5-minute reminder
        scheduleEventNotification(
          event.event_id,
          `${event.event_title} starts in 5 minutes!`,
          startTime.subtract(5, 'minutes').toDate(),
        )

        // Schedule start time notification
        scheduleEventNotification(
          `${event.event_id}-start`,
          `${event.event_title} is starting now!`,
          startTime.toDate(),
        )
      }
    } catch {
      patchResult.undo()
    }
  }
  return (
    <>
      {user && event.participants.find((userp) => userp.id === user?.id) ? (
        <Button
          variant='contained'
          color={event?.is_live ? 'primary' : 'success'}
          disableElevation
          disableRipple
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (!event?.is_live) {
              e.preventDefault()
            }
          }}
          sx={{
            width: width,
            fontSize: '13px',
            px: 0,
            borderLeft: '4px solid rgba(0, 0, 0, .2)',
            borderTopLeftRadius: ' 6px',
            borderBottomLeftRadius: ' 6px',
          }}
        >
          {event?.is_live ? (
            'Jump in Now'
          ) : (
            <>
              <CheckCircleOutlineIcon sx={{ display: { xs: 'none', md: 'flex', mr: 0.5 } }} />{' '}
              {'  '} You&apos;re going!
            </>
          )}
        </Button>
      ) : (
        <Button
          floating
          highlighted
          // loading={isLoadingParticipateInEvent}
          color='neutral'
          variant='outlined'
          sx={{
            zIndex: 10,
            width: width,
            fontWeight: 600,
            // borderRadius: '5px',
            // width: '100px',
            // height: '40px',
            // fontSize: '16px',
            // color: '#ff9641',
            // background: (theme) => (theme.palette.mode === 'dark' ? '#38281ED9' : 'white'),
            // ':hover': {
            //   background: (theme) => (theme.palette.mode === 'dark' ? '#38281E80' : '#FFFFFF80'),
            // },
          }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            if (!user) {
              navigate(SIMPLE_LOGIN_ROUTE)
              return
            }

            event?.is_live
              ? navigate(`${EVENT_ROUTE}/${event.short_event_id ?? event.event_id}`)
              : handleParticipate(event.event_id)
          }}
        >
          Attend
        </Button>
      )}
    </>
  )
}

export default ParticipantButton
