export const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    console.log('This browser does not support notifications')
    return 'denied'
  }

  if (!('serviceWorker' in navigator)) {
    console.log('Service workers are not supported')
    return 'denied'
  }

  try {
    const permission = await Notification.requestPermission()
    return permission
  } catch (error) {
    console.error('Error requesting notification permission:', error)
    return 'denied'
  }
}

export const scheduleEventNotification = async (
  eventId: string,
  message: string,
  scheduledTime: Date,
) => {
  try {
    const registration = await navigator.serviceWorker.ready

    // Calculate delay until notification should be shown
    const delay = scheduledTime.getTime() - Date.now()
    if (delay < 0) return // Don't schedule if time has passed

    // Schedule the notification
    setTimeout(async () => {
      await registration.showNotification('iSpeak Event', {
        body: message,
        icon: '/icon.png', // Add your app icon path
        badge: '/badge.png', // Add your badge icon path
        tag: eventId, // Ensures we don't show duplicate notifications
        vibrate: [200, 100, 200],
      })
    }, delay)
  } catch (error) {
    console.error('Error scheduling notification:', error)
  }
}
